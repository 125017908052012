import type { BlockTypes } from '@etf1-interne/tf1info_types_news'
import React from 'react'
import { mixins, theme } from '../../../styles'

export interface IHello {
  title: string
  subtitle: string
}

export const Hello = React.memo(function Hello({
  title,
  subtitle,
}: BlockTypes['hello']['data']): JSX.Element {
  return (
    <>
      <div className="Hello">
        <span>
          <strong>{title}</strong>
          <br />
          <div className="flex items-center">
            <h1 className="Hello__Subtitle">{subtitle}</h1>
          </div>
        </span>
      </div>

      <style jsx>{`
        .Hello {
          font-size: 18px;
          font-style: normal;
          font-weight: 300;
          line-height: 26px;
          color: ${theme.cssVars.deepBlue};
          margin: 30px 30px 25px 30px;
        }

        .Hello__Subtitle > :global(img) {
          width: 55px;
          height: 100%;
          margin-left: 10px;
        }

        .Hello__Subtitle {
          font-size: 18px;
          font-style: normal;
          font-weight: 300;
          line-height: 26px;

          color: ${theme.cssVars.deepBlue};
        }

        strong {
          font-size: 38px;
          font-weight: 800;
          line-height: 46px;
          margin-bottom: 12px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .Hello__Subtitle > :global(svg) {
            width: 83px;
            height: 100%;
            margin-left: 10px;
          }
        }
      `}</style>
    </>
  )
})
